import React, { Component } from 'react'
import Typewriter from 'typewriter-effect';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';

export default class Intro extends Component {
  render() {
    return (
      <section className="hero-wrap" id="home-section">
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text justify-content-center align-items-center" style = {{height:"100vh"}}>
          <div className="col-lg-8 col-md-6 align-items-center">
            <div className="text text-center">
              <span className="subheading">Hey! I am</span>
              <h1>Joshua Barndt</h1>
              <h2>I'm a <Typewriter options={{ strings: ['Project Manager','Backend Developer', '.Net Core Developer', 'Python Developer'], autoStart: true, loop: true,}}/>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="mouse">
        <a href="#about-section" className="mouse-icon">
          <div className="mouse-wheel"><ArrowDownwardOutlinedIcon/></div>
        </a>
      </div>
    </section>

)
}
}