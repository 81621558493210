
import Home from './components/Home'
import { Switch, Route, Redirect, BrowserRouter  } from 'react-router-dom';
import './App.css';

import ReactGA from "react-ga4"

export const App = () => {
ReactGA.initialize("G-BXPTZV3H11");
ReactGA.send("pageview");
  return (
    <BrowserRouter >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
    </BrowserRouter >

  );
};
export default App;
