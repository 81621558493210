import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
        <section className="ftco-about img ftco-section ftco-no-pt ftco-no-pb" id="about-section">
    	<div className="container" style= {{paddingTop: "70px"}}>
    		<div className="row d-flex no-gutters">
    			<div className="col-md-6 col-lg-6 d-flex" style = {{alignItems: "center"}}>
    				<div className="img-about img d-flex align-items-stretch" >
    					<div className="overlay"></div>
	    				<img src= "images/HeadShot.png" width="400" height="500" className =" center" alt= "Head Shot"/>
    				</div>
    			</div>
    			<div className="col-md-6 col-lg-6 pl-md-5 py-5">
    				<div className="row justify-content-start pb-3">
		          <div className="col-md-12 heading-section ftco-animate">
		            <h2 className="mb-4">About Me</h2>
		            <p>I live and work in Upstate New York. At my day job, I work as a Senior Manager Risk &amp; Compliance for 
						GE. </p>
					<p>
					I graduated Rochester Institute of Technology with a bachelors in Manufacturing Engineering Technology. 
					I began work fresh out of college and quickly discovered the wonders of software engineering and project management. 
					I am a firm believer that anyone can learn something new and that everyone should strive to grow and develop.
					</p>
					<p>
					So here I am 10 years later, continuing to improve and develop my skills. 
					</p>
		            <ul className="about-info mt-4 px-md-0 px-2">
		            	<li className="d-flex"><span>Name:</span> <span>Joshua Barndt</span></li>
		            	<li className="d-flex"><span>Location:</span> <span>Schenectady, New York, USA</span></li>
		            	<li className="d-flex"><span>Email:</span> <span>jrb3625@gmail.com</span></li>
		            	<li className="d-flex"><span>Phone: </span> <span>+1-518-788-8477</span></li>
		            </ul>
		          </div>
		        </div>
	          <div className="counter-wrap ftco-animate d-flex mt-md-3">

                <p><a href="CV_JBarndt.docx" className="btn btn-primary py-3 px-3">Download CV</a></p>
	          </div>
	        </div>
        </div>
    	</div>
    </section>
    )
  }
}