import React, { Component } from 'react'
import { Link } from "react-scroll";
import WorkIcon from '@material-ui/icons/Work';

export default class Resume extends Component {
  render() {
    return (
        <section className="ftco-section ftco-no-pb goto-here" id="resume-section">
    	<div className="container" style = {{paddingBottom: "70px"}} >
    		<div className="row justify-content-start">
    			<div className="col-md-3">
				    <nav id="navi">
					    <ul>
					      <li><Link to="page-1">Education</Link></li>
					      <li><Link to="page-2">Experience</Link></li>
					      <li><Link to="page-3">Skills</Link></li>
					    </ul>
					  </nav>
					</div>
					<div className="col-md-9" >
					  <div id="page-1" className= "page one"  >
					  	<h2 className="heading" style= {{paddingTop: "70px"}}>Education</h2>
					    <div className="resume-wrap d-flex ftco-animate">
							<div className="align-items-center">
					    	<img src= "images/RIT.jpg" width ="40" hight = "auto" alt= "RIT Logo"/>
							</div>
					    	<div className="text pl-3">
							
		    					<h2>Bachelor of Science in Manufacturing Engineering</h2>
								
		    					<span className="position">Rochester Institute of Technology</span>
								<br/>
								<span className="date">2006-2011</span>
		    					<p>Bachlors degree with a focus on advanced manufacturing technology, robotics, and automation.</p>
	    					</div>
	    				</div>
					  </div>

					  <div id="page-2" className= "page two">
					  	<h2 className="heading" style= {{paddingTop: "70px"}}>Experience</h2>
						  <div className="resume-wrap d-flex ftco-animate">
					    	<div className="align-items-center">
							<img src= "images/GE.jpg" width ="45" hight = "auto" alt= "GE Logo"/>
					    	</div>
					    	<div className="text pl-3">
		    					
		    					<h2>Senior Manager, Risk &amp; Compliance (Remote)</h2>
		    					<span className="position">General Electric</span>
								<br/>
								<span className="date">June 2022 - Present</span>
		    					<p>
									<ul>
										<li>Steered team of three engineers to manage GE’s internal compliance goals as well as maintain 
											customer goals &amp; responsibilities.</li>
										<li>Develop a compliance program to meet NERC regulations, in close communication with 
											customers and internal teams; authored comprehensive roadmap to coordinate compliance efforts.</li>
										<li>Provided primary point of contact for customers, resolving issues and 
											inquiries to keep clients informed and on-board.</li>
										<li>Revised and refined company policies and procedures in accordance with NERC Standards.</li>
										<li>Collaborated with high-voltage teams to ensure activities are carried out in accordance 
											with customer requirements and all pertinent regulations; assisted in procedure and 
											documentation collection.</li>
										<li>Assess compliance and grid-security risks, utilizing industry-standard and emerging 
											techniques to evaluate potential sources of legal, financial, and infrastructural damage, 
											as well as creating strategies and allocating resources to minimize them.</li>
									</ul>
								</p>
		    				</div>
	    				</div>
					    <div className="resume-wrap d-flex ftco-animate">
					    	<div className="align-items-center">
							<img src= "images/GE.jpg" width ="45" hight = "auto" alt= "GE Logo"/>
					    	</div>
					    	<div className="text pl-3">
		    					
		    					<h2>Digital Transformation Leader (Remote)</h2>
		    					<span className="position">General Electric</span>
								<br/>
								<span className="date">July 2020 - June 2022</span>
		    					<p>
									<ul>
										<li>Partnered with cross global functional teams to collect, curate, and introduce features to support 
											expansion of four operation centers, as needed to support a fleet growing by up to 20% per annum.</li>
										<li>Crafted successful automation and reporting project plans, designating objectives and identifying 
											resource and staffing requirements to deliver results on time and on budget.</li>
										<li>Authored and presented regular reports to senior management, covering project budget, 
												progress, and quality developments.</li>
										<li>Leveraged agile methodology to reduce cycle time and achieve margin targets.</li>
										<li>Piloted introduction of digital control and equipment-monitoring methodologies to improve 
											operating efficiency and reliability by minimizing downtime, optimizing maintenance, 
											adjusting turbine configurations, and analyzing moment-to-moment grid demand.</li>
									</ul>
								</p>
		    				</div>
	    				</div>
	    				<div className="resume-wrap d-flex ftco-animate">
						<div className="align-items-center">
							<img src= "images/GE.jpg" width ="45" hight = "auto" alt= "GR Logo"/>
					    	</div>
					    	<div className="text pl-3">
								<h2>Operations Manager</h2>
								<span className="position">General Electric</span>
								<br/>
		    					<span className="date">September 2018 - July 2020</span>
								<ul>
									<li>Forged new team to take on additional contract responsibility, handling hiring and 
										training while establishing group cohesion and personnel quality.</li>
									<li>Provided oversight and accountability to a team of seven, engineering an inclusive 
										and goals-oriented environment to foster employee commitment and productivity.</li>
									<li>Transformed remote turbine operations, introducing substation monitoring to 
										GE’s Service Offerings.</li>
									<li>Conferred with customers to drive development of a new contract offering and expand scope 
										of GE’s Monitoring Services, garnering actionable insight and leveraging it to align product 
										specifications with market demand and expectations.</li>
									<li>Sourced, vetted, and managed vendors as needed to accomplish project goals and meet budgetary 
										requirements.</li>
									<li>Combined efforts with process owners to refine procedures, devise best practices, and address 
										quality audit findings.</li>
									<li>
										<ul>
											<li>Examples Include:</li>
											<li>
												<ul>
													<li>Developing new procedures to plan site outages so necessary regulatory obligations are met.</li>
													<li>Defining maintenance &amp; inspection cycles for field personnel and establishing an evidence repository</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
								<b>KEY ACHIEVEMENT</b>
								<p>Operated as an subject matter expert for GE’s “Data as a Service” offering, securing over $2M in future contracts.</p>
							</div>
	    				</div>
	    				<div className="resume-wrap d-flex ftco-animate">
						<div className="align-items-center">
							<img src= "images/GE.jpg" width ="45" hight = "auto" alt= "GE Logo"/>
					    	</div>
					    	<div className="text pl-3">
		    					<h2>Technical Support Manager</h2>
		    					<span className="position">General Electric</span>
								<br/>
								<span className="date">July 2015 - September 2018</span>
								<ul>
									<li>Piloted a team of five technicians in the monitoring of over 11,000 wind turbines across 
										North and South America.</li>
									<li>Orchestrated a global initiative to ensure comprehensive tool and technique adoption, 
										maintaining uniform customer service standards across three operations centers comprising 
										of fifty plus personnel.</li>
									<li>Served as key contributor to development of a new ticketing system, whose quality 
										eventually earned it a place in GE’s digital offering lineup.</li>
									<li>Drew up business KPIs for GE’s global operations centers.</li>
									<li>Carried out performance evaluations and problem-solving initiatives to deliver results 
										for internal and external customers and more effectively meet KPIs.</li>
									<li>Spearheaded team’s transition from a contractor-run to a GE-run structure, 
										securing cost improvements and measurable quality gains while anticipating, pre-empting, and 
										resolving issues.</li>
								</ul>
								<b>KEY ACHIEVEMENT</b>
								<p>Developed in-house automation tools, boosting productivity by 25%.</p>
							
		    				</div>
	    				</div>
	    				<div className="resume-wrap d-flex ftco-animate">
						<div className="align-items-center">
							<WorkIcon fontSize='large' style={{color:'#3e64ff'}}></WorkIcon>
					    	</div>
					    	<div className="text pl-3">
		    					<h2>Other Experiance</h2>
								<ul>
									<li>
										<span className="position">Senior Specialist Engineer (Globalfoundries)</span>
										<br/>
										<span className="date">November 2013 - January 2015</span>
									</li>
									<li>
										<span className="position">Manufacturing Engineer (Globalfoundries)</span>
										<br/>
										<span className="date">June 2011 - November 2013</span>
									</li>
								</ul>
		    					
		    				</div>
	    				</div>
					  </div>
					  <div id="page-3" className= "page three">
					  	<h2 className="heading" style= {{paddingTop: "70px"}}>Skills</h2>
					  	<div className="row">
						  <div className="col-md-6 animate-box">
									<div className="progress-wrap ftco-animate">
										<h3>Project Management</h3>
										<div className="progress">
										 	<div className="progress-bar color-1" role="progressbar" aria-valuenow="90"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:'90%'}}>
										    <span>90%</span>
										  	</div>
										</div>
									</div>
								</div>
					  		<div className="col-md-6 animate-box">
									<div className="progress-wrap ftco-animate">
										<h3>Java</h3>
										<div className="progress">
										 	<div className="progress-bar color-1" role="progressbar" aria-valuenow="85"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:'85%'}}>
										    <span>85%</span>
										  	</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 animate-box">
									<div className="progress-wrap ftco-animate">
										<h3>.Net Core / ASP .Net</h3>
										<div className="progress">
										 	<div className="progress-bar color-2" role="progressbar" aria-valuenow="85"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:'85%'}}>
										    <span>85%</span>
										  	</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 animate-box">
									<div className="progress-wrap ftco-animate">
										<h3>Python</h3>
										<div className="progress">
										 	<div className="progress-bar color-3" role="progressbar" aria-valuenow="60"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:'60%'}}>
										    <span>60%</span>
										  	</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 animate-box">
									<div className="progress-wrap ftco-animate">
										<h3>SQL</h3>
										<div className="progress">
										 	<div className="progress-bar color-4" role="progressbar" aria-valuenow="90"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:'90%'}}>
										    <span>90%</span>
										  	</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 animate-box">
									<div className="progress-wrap ftco-animate">
										<h3>HTML / CSS</h3>
										<div className="progress">
										 	<div className="progress-bar color-5" role="progressbar" aria-valuenow="50"
										  	aria-valuemin="0" aria-valuemax="100" style={{width:'50%'}}>
										    <span>50%</span>
										  	</div>
										</div>
									</div>
								</div>
								
					  	</div>
					  </div>
					</div>
			  </div>
    	</div>
    </section>

    )
  }
}