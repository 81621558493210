import React, { Component } from 'react'
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

export default class Footer extends Component {
  render() {
    return (
    <footer className="ftco-footer">
    <div className="container">
      <div className="row mb-5">
      <div className="row">
          <div className="ftco-footer-widget mt-3">
                <ul className = "list-unstyled" style = {{justifyContent : "center", display : "flex"}}>
                  <li style={{padding : "0 10px 0 10px"}}><a href="tel:+1 518-788-8477"><PhoneIcon></PhoneIcon><span className="text"> Call Me</span></a></li>
                  <li style ={{padding : "0 10px 0 10px"}}><a href="mailto:jrb3625@gmail.com"><MailIcon></MailIcon><span className="text"> Email Me</span></a></li>
                </ul>
          </div>
        </div>
        <div className="row">
          <div className="mt-3">
            <ul className="ftco-footer-social list-unstyled mt-3" style = {{justifyContent : "center", display : "flex"}} >
              <li className="ftco-animate"><a  href="https://github.com/jrb3625"><GitHubIcon center="true" style={{ color: 'white', fontSize :"50" }}></GitHubIcon></a></li>
              <li className="ftco-animate"><a href="https://www.linkedin.com/in/joshua-barndt-6089b546/"><LinkedInIcon center="true" style={{ color: 'white', fontSize :"50" }}></LinkedInIcon></a></li>
              <li>
                
                <a  href="https://www.facebook.com/joshua.barndt.3">
                  <FacebookIcon center="true" style={{ color: 'white', fontSize :"50" }}></FacebookIcon>
                </a>
              </li>
              
            </ul>
          </div>
        </div>
        
      </div>
      <div className="row">
        <div className="col-md-12 text-center">

          <p>
Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved 
</p>
        </div>
      </div>
    </div>
  </footer>
    )
  }
}