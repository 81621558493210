import React, { Component } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';

export default class Navigation extends Component {
  render() {
    return (
      <Navbar style= {{ top: 0}}collapseOnSelect fixed='top' expand='sm' bg='light' variant='light'>
        <Container>
        <Navbar.Brand href="#home-section">Joshua Barndt</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
        <Navbar.Collapse id = 'responsive-navbar-nav'>
          <Nav  className="mr-auto">
            <Nav.Link href="#home-section"><span>Home</span></Nav.Link>
            <Nav.Link href="#about-section"><span>About</span></Nav.Link>
            <Nav.Link href="#resume-section"><span>Resume</span></Nav.Link>

          </Nav>

        </Navbar.Collapse>
        </Container>

      </Navbar>
        
    )
  }
}