  
import { Fragment,useEffect  } from 'react';
import Navigation from './navigation'
import Intro from './intro'
import About from './about'
import Resume from './resume'
import Footer from './footer'

import AOS from "aos";
import "aos/dist/aos.css"


function Home() {
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
  
    return (
      // your components
      <Fragment>
      <Navigation></Navigation>
      <Intro></Intro>
      <About></About>
      <Resume></Resume>
      <Footer></Footer>
  
    </Fragment>
    );
  }

export default Home;